import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import ImageTable from 'components/Web_User_Interface/720p_Series/Multimedia/Image/imageTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Multimedia Menu // Image",
  "path": "/Web_User_Interface/720p_Series/Multimedia/Image/",
  "dateChanged": "2017-12-08",
  "author": "Mike Polinowski",
  "excerpt": "Here you can adjust the image parameters brightness, saturation, contrast, exposure (shutter) and sharpness. You can flip and mirror the video image, can display a timestamp and set a camera name and display it as well. The displayed parameters are burnt into the camera image. Please make sure to restart the camera after changing the name.",
  "image": "./WebUI_720p_SearchThumb_Multimedia_Image.png",
  "social": "/images/Search/WebUI_720p_SearchThumb_Multimedia_Image.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "720p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Multimedia Menu // Image' dateChanged='2017-12-08' author='Mike Polinowski' tag='INSTAR IP Camera' description='Here you can adjust the image parameters brightness, saturation, contrast, exposure (shutter) and sharpness. You can flip and mirror the video image, can display a timestamp and set a camera name and display it as well. The displayed parameters are burnt into the camera image. Please make sure to restart the camera after changing the name.' image='/images/Search/WebUI_720p_SearchThumb_Multimedia_Image.png' twitter='/images/Search/WebUI_720p_SearchThumb_Multimedia_Image.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/720p_Serie/Multimedia/Bildeinstellungen/' locationFR='/fr/Web_User_Interface/720p_Series/Multimedia/Image/' crumbLabel="Image" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "720p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#720p-web-user-interface",
        "aria-label": "720p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`720p Web User Interface`}</h1>
    <h2 {...{
      "id": "multimedia-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#multimedia-menu",
        "aria-label": "multimedia menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Multimedia Menu`}</h2>
    <h3 {...{
      "id": "image",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#image",
        "aria-label": "image permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Image`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <blockquote>
      <p parentName="blockquote">{`Here you can adjust the image parameters brightness, saturation, contrast, exposure (shutter) and sharpness. You can flip and mirror the video image, can display a timestamp and set a camera name and display it as well. The displayed parameters are burnt into the camera image. Please make sure to restart the camera after changing the name.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "716px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/61cf4c134de97079608144d2c7375ac4/6bbf7/WebUI-Multimedia_Image.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAIAAADJt1n/AAAACXBIWXMAAAsSAAALEgHS3X78AAADD0lEQVQ4y43U7XKaWBjAcS9sdy9jX6aXkMnH7j1svybZVFZCMKJjrSnRpBgViSIQBXyJIVgtEN7Ug4eA8QZaSR3Trp3294VP/3nOPOcMMZI8+/2PP3/59be/XrzAMAzH8Uw6c4zjKIpiGJY6SeE4fpI6QRAkHkdwHCcIIplMJhKJ/xKJ2MXFxevD1/+8epVA0W6vNxgM+v1+t9tVVfXD6MnqOxwOVVW9v79nGGZ3d5emq/V6PZbJZIg0QZJkpVI1TXM4VBXlbnBzA31/uVwuFoswDIMgCNeCIDg9PeV5vt/vx1Kpk/39/XQ6TVGUZVm1Wi2fz2ezWYIg8vl8oVCYzWbz+dyLAAB836dpmuM4WZZj6TQRRxCSJC8vL23b6nY6jQZ7d3enqupwOByPx77vP6zBSKVS4Tiu0+nEcByPx+NvstlisWia5sSdAACezhlE4DMAAMuyqtXql7hEUWdnZ4VCoVZjHMcxdF37qEEI/bWHZyCE0+l0E9uWbejGYrGYzcB0OjVN03acIAgetvk2fpt7+/fLlzs7OwRBQAh1TbctG/o+3AYAYNv2Js7lcrk3OY7jaJq2LEvXdd0w4Hd4njeZTDbx8fHx4eG/CIK8e0falmVZpuu68/n8e5Nd193EAi905K4oivV6PVqYoWkfIYTBNhDCr+LbwW0nIkmS4zi2ZQcPq3vyt/E8bzabbWKWZSmKKpVKHMdNJpPRh5F5b05nU9/3/7/zbx8Jz/OVSqVarfI877quYRjj0UhRFF3Xty7sq2O3Wy26VqvX64Ig2LataZqqqrquAwDCMFwul4+Pj4u1MAw9zyuXy1/e9vX1daVcZhhGEATP8wzDUBRF0zTHcUzTHI1G4/HYXDMMAwDAMEyz2VxNlmWZZVlBEDqy3Gg08GQSi6AoenR0tLe3d3BwgGHYUQRFUYIgMplMq9VaTeY47urqirli2u02TdPFYvEi8j5ClUoURb1/5vz8nKZpWZYlSVpdVb97c3urSJLUi/4kNz/S6/VEUVzFgsCzLNtsNnmeF0Wx/ROeys/xJ+Ry/Dy9A20vAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/61cf4c134de97079608144d2c7375ac4/e4706/WebUI-Multimedia_Image.avif 230w", "/en/static/61cf4c134de97079608144d2c7375ac4/d1af7/WebUI-Multimedia_Image.avif 460w", "/en/static/61cf4c134de97079608144d2c7375ac4/04ac8/WebUI-Multimedia_Image.avif 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/61cf4c134de97079608144d2c7375ac4/a0b58/WebUI-Multimedia_Image.webp 230w", "/en/static/61cf4c134de97079608144d2c7375ac4/bc10c/WebUI-Multimedia_Image.webp 460w", "/en/static/61cf4c134de97079608144d2c7375ac4/d8378/WebUI-Multimedia_Image.webp 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/61cf4c134de97079608144d2c7375ac4/81c8e/WebUI-Multimedia_Image.png 230w", "/en/static/61cf4c134de97079608144d2c7375ac4/08a84/WebUI-Multimedia_Image.png 460w", "/en/static/61cf4c134de97079608144d2c7375ac4/6bbf7/WebUI-Multimedia_Image.png 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/61cf4c134de97079608144d2c7375ac4/6bbf7/WebUI-Multimedia_Image.png",
              "alt": "Web User Interface - 720p Series - Multimedia Image",
              "title": "Web User Interface - 720p Series - Multimedia Image",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ImageTable mdxType="ImageTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Here you can adjust the image parameters brightness, saturation, contrast, exposure (shutter) and sharpness. You can flip and mirror the video image, can display a timestamp and set a camera name and display it as well. The displayed parameters are burnt into the camera image. Please make sure to restart the camera after changing the name.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      